@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Inter";
  font-weight: 400;
  src: url("./assets/font/Inter-Regular.ttf");
}

@font-face {
  font-family: "Inter";
  font-weight: 500;
  src: url("./assets/font/Inter-Medium.ttf");
}

@font-face {
  font-family: "Inter";
  font-weight: 600;
  src: url("./assets/font/Inter-SemiBold.ttf");
}

@font-face {
  font-family: "Inter";
  font-weight: 700;
  src: url("./assets/font/Inter-Bold.ttf");
}

body {
  margin: 0;
  font-family: "Inter";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

.scrollbar {
  scrollbar-width: thin;
  scrollbar-color: white white;
}

.scrollbar::-webkit-scrollbar {
  width: 5px;
}

.scrollbar::-webkit-scrollbar-track {
  background: white;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: white;
}

.Toastify__toast{
  z-index: 999999;
}

.Toastify__toast-theme--light {
  border-left: 6px solid;
  border-radius: 4px !important;
  padding: 12px 16px !important;
  width: 390px;
}

.Toastify__toast-body {
  padding: 0 12px 0 0 !important;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #101928;
}

.Toastify__toast--success {
  border-color: #04802e;
}

.Toastify__toast--error {
  border-color: #cb1a14;
}

.Toastify__toast-icon {
  width: 24px !important;
  height: 24px !important;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid;
  margin-inline-end: 12px !important;
}

.Toastify__toast--success .Toastify__toast-icon {
  border-color: #b5e3c4;
  background: #e7f6ec;
  color: #04802e !important;
}

.Toastify__toast--error .Toastify__toast-icon {
  border-color: #f2bcba;
  background: #fbeae9;
  color: #cb1a14 !important;
}

.Toastify__close-button--light {
  opacity: 1 !important;
  align-self: center !important;
  border-left: 1px solid #f0f2f5 !important;
  padding-left: 12px !important;
  min-height: 24px !important;
}

.drop-down {
  visibility: hidden;
  transition: 200ms ease-in;
}
.link-with-dropDown:hover .drop-down {
  visibility: visible;
}
.contact-stroke::after {
  content: "";
  position: absolute;
  top: 4px;
  left: -16px;
  width: 2px;
  height: 52px;
  background: #5fc381;
}

.members .swiper-wrapper {
  align-items: center;
}

/* Swiper Buttons */
.swiper-button-prev,
.swiper-button-next {
  top: auto !important;
  bottom: 34px !important;
  background: #0f973d !important;
  backdrop-filter: blur(8px) !important;
  width: 48px !important;
  height: 48px !important;
  border-radius: 50% !important;
  border: 1px solid #91d6a8 !important;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 20px !important;
  color: #fff !important;
  font-weight: 900 !important;
}

.hero .swiper-button-prev {
  left: auto !important;
  right: 118px !important;
  bottom: 80px !important;
}

.hero .swiper-button-next {
  right: 30px !important;
  bottom: 80px !important;
}

.services .swiper-button-prev {
  right: 88px !important;
  left: auto !important;
}

.services .swiper-button-next {
  right: 0 !important;
  left: auto !important;
}

/* Swiper Pagination */
.swiper-pagination-progressbar {
  width: 151px !important;
  height: 9.5px !important;
  /* background: #f0f2f5 !important; */
  border: 1px solid #91d6a8 !important;
  border-radius: 20px;
  display: flex !important;
  background: #fff !important;
}

.swiper-pagination-progressbar-fill {
  margin: 0 !important;
  border-radius: 20px !important;
}

.swiper-pagination-progressbar-fill {
  background: #0f973d !important;
}

.services .swiper-pagination-progressbar {
  left: 0 !important;
  right: auto !important;
  top: auto !important;
  bottom: 0px !important;
}

.hero .swiper-pagination-progressbar {
  right: 16px !important;
  left: auto !important;
  bottom: 56px !important;
  top: auto !important;
}

.container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

@media only screen and (max-width: 640px) {
  .services .swiper-slide {
    margin-right: 24px !important;
    width: fit-content !important;
  }
}

@media only screen and (max-width: 768px) {
  .services .swiper-pagination-progressbar,
  .services .swiper-button-prev,
  .services .swiper-button-next {
    display: none !important;
  }
}

@media only screen and (max-width: 1023.5px) {
  .desktop-nav {
    display: none;
  }

  .drop-down {
    visibility: visible;
    z-index: 999999;
    height: 390px;
  }
}

@media only screen and (min-width: 1024px) {
  .mobile-nav {
    display: none;
  }
}

@media only screen and (min-width: 600px) {
  .container {
    max-width: 600px;
  }

  .hero .swiper-button-prev {
    left: auto !important;
    right: 201px !important;
  }

  .hero .swiper-button-next {
    right: 113px !important;
  }

  .hero .swiper-pagination-progressbar {
    right: 100px !important;
  }
  .services .swiper-pagination-progressbar {
    right: 0 !important;
    left: auto !important;
  }
}

@media only screen and (min-width: 768px) {
  .container {
    max-width: 768px;
  }

  .hero .swiper-button-prev {
    left: auto !important;
    right: 202px !important;
  }

  .hero .swiper-button-next {
    right: 114px !important;
  }

  .hero .swiper-pagination-progressbar {
    right: 100px !important;
  }
}

@media only screen and (min-width: 1024px) {
  .container {
    max-width: 960px;
  }

  .hero .swiper-button-prev {
    left: auto !important;
    right: 194px !important;
  }

  .hero .swiper-button-next {
    right: 106px !important;
  }
  .hero .swiper-pagination-progressbar {
    right: 90px !important;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
  .nav-link {
    white-space: nowrap;
  }
}

@media only screen and (min-width: 1400px) {
  .container {
    max-width: 1216px;
  }
}
